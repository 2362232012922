(<any>window).fbAsyncInit = function () {
    FB.init({
        appId: fbAppId,
        autoLogAppEvents: true,
        xfbml: true,
        version: 'v19.0',
    });
    //$('html body').trigger('facebookinitialized');
    console.debug('facebook initialized');
};

//(function (d, s, id) {
//    var js, fjs = d.getElementsByTagName(s)[0];
//    if (d.getElementById(id)) { return; }
//    js = d.createElement(s); js.id = id;
//    js.src = "https://connect.facebook.net/en_US/sdk.js";
//    fjs.parentNode.insertBefore(js, fjs);
//}(document, 'script', 'facebook-jssdk')); 

if (initFacebook) {
    (function (d, s, id) {
       
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) { return; }
        js = d.createElement(s); js.id = id;
        js.src = "//connect.facebook.net/en_US/sdk.js";
        fjs.parentNode.insertBefore(js, fjs);
        //checkLoginStatus();
    }(document, 'script', 'facebook-jssdk'));
}




function checkLoginStatusCallback(response, action, password, isKiosk?) {
    console.log("checkLoginStatusCallback : " + response.status + ' ' +  action);

    if (response.status === 'connected') {

        // the user is logged in and has authenticated your
        // app, and response.authResponse supplies
        // the user's ID, a valid access token, a signed
        // request, and the time the access token
        // and signed request each expire
        //var uid = response.authResponse.userID;
        var accessToken = response.authResponse.accessToken;

        var data = { accessToken: accessToken, ReturnUrl: CSParameters.ReturnUrl, password: password, isKiosk: isKiosk };

        CompuSport.Ajax.Post(CSParameters.BaseUrl + 'Account/' + action, data, function (response: any) {
            console.log("AjaxPos Completed : " + action + ' ' + response.message + ' ReturnUrl:' + decodeURIComponent(response.returnUrl));
            if (response.message == 'AlreadyLinked') {
                console.log("replacing url : " + response.returnUrl);
                // On dirait que location.replace ne gere pas bien le cookie d'authentication
                window.location.assign(decodeURIComponent(response.returnUrl));
            } else if (response.message == "KioskNotApproved") {
                console.log("KioskNotApproved : " + response.kioskMessage);
                let popup = new CompuSport.Controls.Popup();
                popup.Show(response.kioskMessage, { PopupId: 'AutoLinkFacebookMessage', Buttons: 'Ok'  });
            } else if (response.message == "KioskApproved") {
                console.log("KioskApproved replacing url : " + response.returnUrl);
                window.location.assign(decodeURIComponent(response.returnUrl));
            } else {
                if (response.message != null && response.message != "") {
                    if (response.message != "Error") {
                        let popup = new CompuSport.Controls.Popup();
                        popup.Show(response.message, { PopupId: 'AutoLinkFacebookMessage', Buttons: 'Ok'  });
                        popup.OnClosed = function () {
                            window.location.replace(decodeURIComponent(response.returnUrl));
                        };
                    } else {
                        console.log("checkLoginStatusCallback: error");
                    }

                } else {
                    if (window.location.search.toLowerCase().indexOf('mobileappevent') != -1) {
                        //Enlever le mobileappevent pour ne pas que l'app le reexecute
                        var params = {};
                        window.location.search.toLowerCase().replace(/[?&]+([^=&]+)=([^&]*)/gi, (str, key, value) => { params[key] = value; return value; });
                        params["mobileappevent"] = '';
                        var url = window.location.pathname + "?" + $.param(params);
                        window.location.replace(url);
                    } else {
                        //Ici, response est undefined, donc, besoin du reload
                        window.location.reload();
                    }
                }
            }
        });
    }
    return true;
}